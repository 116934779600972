/* Calibre */
@font-face {
  font-family: "calibre";
  src: url("./assets/fonts/calibre-web-light.woff");
  font-weight: 200;
}
@font-face {
  font-family: "calibre";
  src: url("./assets/fonts/calibre-web-regular.woff");
  font-weight: 400;
}
@font-face {
  font-family: "calibre";
  src: url("./assets/fonts/calibre-web-medium.woff");
  font-weight: 500;
}
@font-face {
  font-family: "calibre";
  src: url("./assets/fonts/calibre-web-semibold.woff");
  font-weight: 600;
}

/* Serif */
@font-face {
  font-family: "sourceSerif";
  src: url("./assets/fonts/SourceSerifPro-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "sourceSerif";
  src: url("./assets/fonts/SourceSerifPro-SemiBold.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "sourceSerif";
  src: url("./assets/fonts/SourceSerifPro-Bold.ttf");
  font-weight: 600;
}

/* WorkSans*/
@font-face {
  font-family: "workSans";
  src: url("./assets/fonts/WorkSans-ExtraLight.otf");
  font-weight: 200;
}
@font-face {
  font-family: "workSans";
  src: url("./assets/fonts/WorkSans-Regular.otf");
  font-weight: 400;
}
@font-face {
  font-family: "workSans";
  src: url("./assets/fonts/WorkSans-Bold.otf");
  font-weight: 500;
}

/* Kansas */ 
@font-face {
  font-family: "kansas";
  src: url("./assets/fonts/KansasNew-Thin.otf");
  font-weight: 100;
}
@font-face {
  font-family: "kansas";
  src: url("./assets/fonts/KansasNew-Medium.otf");
  font-weight: 400;
}
@font-face {
  font-family: "kansas";
  src: url("./assets/fonts/KansasNew-Bold.otf");
  font-weight: 500;
}

html {
  font-size: 18px;
  font-family: "calibre";
  font-weight: 200;
}

@media (max-width: 992px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 14px;
  }
}

body {
  color: #38493c;
  height: 100%;
  margin: 0;
}

h1,
h2,
h3 {
  font-family: workSans;
  margin: 0;
}

a {
  color: #f9a14d;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

span.bold {
  font-weight: 500;
}
